<template>
    <div id="content" class="operationalTimers">
        <template v-if="form">
            <Form :id="operationalTimerID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <Button
                    :customClass="{ disabled: !hasAccess }"
                    bType="create"
                    :bCallback="addOperationalTimer"
                    bLabel="tools.operationalTimer.addButton"
                ></Button>
            </div>

            <div class="operationalTimers-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- sidebar filters -->
                <div
                    class="sidebar-filters"
                    :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }"
                >
                    <FilterTimers
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                        :isDisabled="noChanges && Object.values(items).length == 0"
                        @resetPageDatatable="top(true)"
                    ></FilterTimers>
                </div>
                <!-- table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        fixed-header
                        :items="Object.values(items)"
                        sort-by="name"
                        :sort-asc="true"
                        :single-select="singleSelect"
                        show-select
                        hide-default-footer
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                        item-key="item.item.id"
                    >
                        <template #item.icon="item">
                            <div @click="goToReport(item.item.id)">
                                <div
                                    class="icon"
                                    :style="[
                                        item.item.icon
                                            ? { backgroundImage: `url(${item.item.icon})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }
                                            : { backgroundImage: '' }
                                    ]"
                                ></div>
                            </div>
                        </template>
                        <template #item.name="item">
                            <div class="name" @click="goToReport(item.item.id)">
                                <!-- <div class="icon" :style="[item.item.icon ? { backgroundImage: item.item.icon } : { backgroundImage: '' }]"></div> -->
                                <span class="issueName">{{ item.item.name }}</span>
                            </div>
                        </template>
                        <template #item.description="item">
                            <div @click="goToReport(item.item.id)">
                                <span class="issueName">{{ item.item.description }}</span>
                            </div>
                        </template>
                        <template #item.configuration="item">
                            <div class="status" @click="goToReport(item.item.id)">
                                <span class="config">{{ item.item.times }}</span>
                            </div>
                        </template>
                        <template #item.locations="item">
                            <div @click="goToReport(item.item.id)">
                                <Tags
                                    v-if="item.item.locations && item.item.locations.length > 0"
                                    :backImage="'location_inactive_s30.svg'"
                                    :tags="Object.values(item.item.locations)"
                                    :nameTag="'name'"
                                    value="id"
                                ></Tags>
                                <Tags
                                    v-else-if="item.item.groups && item.item.groups.length > 0"
                                    :backImage="'menu_inactive_s30.svg'"
                                    :tags="Object.values(item.item.groups)"
                                    :nameTag="'name'"
                                    value="id"
                                ></Tags>
                                <div class="all-locations" v-else>{{ $t('product.all_available') }}</div>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div class="status" @click="goToReport(item.item.id)">
                                <TableStatuses
                                    :text="item.item.status == '1' ? $t('sites.form.active') : item.item.status == '-2' ? $t('sites.form.inactive') : ''"
                                    :status="item.item.status == '1' ? 'active' : 'inactive'"
                                >
                                </TableStatuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && OperationalTimersLoaded && Object.values(items).length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && OperationalTimersLoaded && Object.values(items).length == 0">
                        <EmptyTable
                            @buttonAction="addOperationalTimer"
                            :buttonText="$t('product.product_category.addButton')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.products_categories')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '@/components/ui/TableStatuses.vue'
import FilterTimers from '@/components/domain/operationalTimers/filter'
import Form from '@/components/domain/operationalTimers/form'
import EmptyTable from '@/components/ui/EmptyTable'

export default {
    components: {
        FilterTimers,
        Form,
        EmptyTable,
        TableStatuses
    },
    name: 'OperationalTimers',
    data() {
        return {
            noChanges: false,
            operationalTimerID: undefined,
            form: false,
            values: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    align: 'start',
                    sortable: true,
                    value: 'icon',
                    class: 'header-table'
                },
                {
                    text: this.$t('tools.operationalTimer.name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    class: 'header-table'
                },
                {
                    text: this.$t('tools.operationalTimer.describ'),
                    value: 'description',
                    align: 'start',
                    class: 'header-table'
                },
                {
                    text: this.$t('tools.operationalTimer.config'),
                    value: 'times',
                    align: 'start',
                    class: 'header-table'
                },
                {
                    text: this.$t('tools.operationalTimer.ubi'),
                    align: 'start',
                    sortable: true,
                    value: 'locationsString',
                    class: 'header-table'
                },
                {
                    text: this.$t('tools.operationalTimer.state'),
                    align: 'start',
                    sortable: true,
                    value: 'status',
                    class: 'header-table',
                    formatter: (value, item) => {
                        return value === '1' ? $t('sites.form.active') : value === '-2' ? $t('sites.form.inactive') : ''
                    }
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            const numItems = this.$store.getters['operationalTimers/getNumItems']
            return numItems ? Math.ceil(numItems / this.pagination.itemsPerPage) : 1
        },
        numItems() {
            return this.$store.getters['operationalTimers/getNumItems']
        },
        items() {
            const result = this.$store.getters['operationalTimers/getOperationalTimers']()
            const arrayOfResults = Object.values(result)
            const formattedItems = arrayOfResults
                .filter((item) => item.status == '1' || item.status == '-2')
                .map((item) => {
                    return {
                        ...item,
                        times: this.formatTimes(item.times),
                        locationsString:
                            item.locations && item.locations.length > 0
                                ? item.locations.map((location) => location.name).join(', ')
                                : item.groups && item.groups.length > 0
                                ? item.groups.map((group) => group.name).join(', ')
                                : this.$t('product.all_available')
                    }
                })

            return formattedItems
        },
        hasAccess() {
            return this.checkPermission('/operationalTimers/add')
        },
        OperationalTimersLoaded() {
            return this.$store.getters['operationalTimers/getOperationalTimers']()
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },
        organizationRender() {
            var org = this.$store.getters['account/getOrganization']

            if (org && org.account && org.languages) {
                var account = org.account
                // var languages = org.languages
                var availableTranslationLanguages = account.config.languages.split(',') != '' ? account.config.languages.split(',') : false

                var result = {
                    allLangs: org.languages,
                    accountLanguage: org.languages[account.lang],
                    availableLangs: availableTranslationLanguages
                }
                return result
            }
            return false
        }
    },
    methods: {
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        goToReport(id) {
            var self = this || {}
            if (this.checkPermission('/operationalTimers')) {
                this.operationalTimerID = id
                this.form = true
            } else {
                var self = this
                self.$snackbar.open({
                    message: self.$t('issue_state.no_permission'),
                    duration: 5000
                })
            }
        },
        addOperationalTimer() {
            if (this.checkPermission('/operationalTimers/add')) {
                this.form = true
                this.operationalTimerID = undefined
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('issue_state.no_permission'),
                    duration: 5000
                })
            }
        },
        closeForm() {
            this.form = false
            this.operationalTimerID = false
            this.$router.push({
                name: 'OperationalTimers',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        load() {
            if (this.$route.params.id) {
                this.goToReport(this.$route.params.id)
            } else if (this.$route.name === 'AddOperationalTimers') {
                this.addOperationalTimer()
            } else if (this.$route.name === 'EditOperationalTimers') {
                this.goToReport(this.operationalTimerID)
            } else {
                this.form = false
                this.operationalTimerID = false
            }
            this.$store.dispatch('operationalTimers/loadFiltersOperationalTimers')

            const items = this.$store.getters['operationalTimers/getOperationalTimers']
            const filters = this.$store.getters['operationalTimers/getOperationalTimersCurrentFilters']

            if (this.$route.name !== 'AddOperationalTimers' && (Object.values(items).length === 0 || Object.values(filters).length === 0)) {
                this.$store.dispatch('operationalTimers/loadOperationalTimers', {})
            }
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('operationalTimersFiltersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('operationalTimersFiltersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        },
        formatHour(hour) {
            if (Number.isInteger(hour)) {
                return `${hour}:00`
            } else {
                const integerPart = Math.floor(hour)
                const fractionalPart = hour - integerPart
                const minutes = fractionalPart * 60
                const formattedMinutes = minutes.toString().padStart(2, '0')
                return `${integerPart}:${formattedMinutes}`
            }
        },
        formatTimes(times) {
            if (!times) {
                return 'Formato de datos de tiempo no válido.'
            }
            if (times.sd != null && times.ed != null && times.iv != null) {
                let intervalDisplay
                const intervalInHours = times.iv / 60

                if (Number.isInteger(intervalInHours)) {
                    if (intervalInHours == 1) {
                        intervalDisplay = `${intervalInHours} ${this.$t('tools.operationalTimer.hour')}`
                    } else {
                        intervalDisplay = `${intervalInHours} ${this.$t('tools.operationalTimer.hours')}`
                    }
                } else {
                    intervalDisplay = `${times.iv} ${this.$t('tools.operationalTimer.minutes')}`
                }

                return (
                    this.$t('tools.operationalTimer.from') +
                    ` ${this.formatHour(times.sd)} ` +
                    this.$t('tools.operationalTimer.to') +
                    ` ${this.formatHour(times.ed)} ` +
                    this.$t('tools.operationalTimer.eachS') +
                    ` ${intervalDisplay}`
                )
            } else if (times.t) {
                times.t.sort((a, b) => a - b)
                return this.$t('tools.operationalTimer.at') + ` ${times.t.map((hour) => this.formatHour(hour)).join(',\n    ')}`
            } else {
                return 'Formato de datos de tiempo no válido.'
            }
        }
    },
    watch: {
        $route(to, from) {
            this.load()
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
        if (this.$route.params.id) {
            this.form = true
            this.operationalTimerID = this.$route.params.id
        } else {
            if (this.$route.name == 'EditOperationalTimers') {
                this.detail = true
            } else {
                this.detail = false
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.operationalTimers {
    .operationalTimers-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            td.text-start {
                font-size: 16px;
                .icon {
                    width: 16px;
                    height: 16px;
                    object-fit: contain;
                }
            }
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: md);
                        font-family: $text-bold;
                    }
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;

                    &.pending {
                        color: $color-warning-800;
                    }
                }
                .name {
                    @include display-flex();
                    @include align-items(center);
                    min-height: 20px;

                    .icon {
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        margin-right: 6px;
                    }
                    .issueName {
                        width: calc(100% - 21px);
                    }
                }
                .type {
                    @include display-flex();
                    .tooltip {
                        margin-left: 6px;
                        width: 17px;
                        height: 17px;
                        @include background($image: img('info_tooltip.svg'));
                    }
                }
                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
